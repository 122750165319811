import React from "react";
const MapPopupBox = ({ imgUrl, eventClosePopup, mapData }) => {
    const {src} = imgUrl
    return ( 
        <>
            <div className={`map-outer-box ${(mapData ? "active-map" : "")}`} onClick={()=> eventClosePopup(!mapData)}>
                <div className="map-inner-box">
                    <div className="map-img-box" onClick={(event) => {event.stopPropagation()}}>
                        <div className="map-close-outer" onClick={()=> eventClosePopup(!mapData)}>
                            <span className="map-close">Close</span>
                        </div>

                        {/* <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TDPNMC1Kyak0YLRSNagwsTA3MzA2MTEzsEw2NDEwtDKoSEtKNjRITEsyTDE3TTQ3SfTiTc9XSEotys3PSylOrQQAkFsUIA&q=go+bermondsey&rlz=1C1GCEA_enIN1019IN1019&oq=&gs_lcrp=EgZjaHJvbWUqDwgAEC4YJxivARjHARjqAjIPCAAQLhgnGK8BGMcBGOoCMgkIARAjGCcY6gIyCQgCECMYJxjqAjIJCAMQIxgnGOoCMgkIBBAjGCcY6gIyCQgFECMYJxjqAjIJCAYQIxgnGOoCMgkIBxAjGCcY6gLSAQ4xOTg3MTAwOTg5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8" className="btn-primary text-uppercase btn-map" target="_blank">View Details</a> */}

                        <a href="https://www.google.com/maps/place/GO+Bermondsey+Office+Space+-+Rental+Office+Space+Bermondsey/@51.4999359,-0.0815856,16z/data=!4m6!3m5!1s0x48760344609c1401:0xfbc10afb1d75a74a!8m2!3d51.5003112!4d-0.0794946!16s%2Fg%2F11f5h5rdly?entry=ttu" className="btn-primary text-uppercase btn-map" target="_blank">View Details</a>
                        
                        

                        <img src={src} alt={"GO Bermondsey-Coworking Office Space in Bermondsey, London"} />
                    </div>
                </div>
            </div>
        </>
     );
}
export default MapPopupBox;