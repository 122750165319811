import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Link from "next/link";
import { useRouter } from "next/router";

import Layout from "../src/components/header/layout";
import Loading from "../src/components/utils/Loading";
import Routes from "../src/routes/Routes";
import { wrapper } from "../src/config/Store";
import { DefaultConfig } from "../src/config/Axios";

import "../src/styles/globals.css";
import "../src/styles/App.scss";
import "../src/styles/sass/our-spaces.scss";
import "../src/styles/sass/header.scss";
import "../src/styles/sass/footer.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

DefaultConfig.setRequests();

moment.tz.setDefault("Europe/London");

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [tourForm, settourForm] = useState(false);
  /* const [popup, setPopup] = useState(false); */
  const [reinitSliderhome, setReinitSliderhome] = useState(false);

  useEffect(() => {
    const handleStart = (url) => setIsLoading(true);
    const handleComplete = (url) => setIsLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  let tourFormToggle = () => {
    settourForm(!tourForm);
  };

  /*  let openPopup = (e) => {
    setPopup(e);
  };

  let closePopup = (e) => {
    setPopup(e);
  }; */

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* <Layout popup={popup} openPopup={openPopup} closePopup={closePopup} tourForm={tourForm} tourFormToggle={tourFormToggle} reinitSliderhome={reinitSliderhome} setReinitSliderhome={setReinitSliderhome}>
        <Component {...pageProps} popup={popup} openPopup={openPopup} closePopup={closePopup} tourForm={tourForm} tourFormToggle={tourFormToggle} reinitSliderhome={reinitSliderhome} setReinitSliderhome={setReinitSliderhome}/>
      </Layout> */}

      <Layout
        tourForm={tourForm}
        tourFormToggle={tourFormToggle}
        reinitSliderhome={reinitSliderhome}
        setReinitSliderhome={setReinitSliderhome}
      >
        <Component
          {...pageProps}
          tourForm={tourForm}
          tourFormToggle={tourFormToggle}
          reinitSliderhome={reinitSliderhome}
          setReinitSliderhome={setReinitSliderhome}
        />
      </Layout>

      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="CookieConsent"
        buttonClasses="cookie_btn_style"
        /* overlay={true} */
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <Link href={Routes?.cookies}>
          <a className="mi_btn"> More info</a>
        </Link>
      </CookieConsent>
    </>
  );
}

// export default MyApp;
export default wrapper.withRedux(MyApp);
