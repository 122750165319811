import React, {memo, useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import Logo1 from "../../../public/assets/images/new_logo1.png";
import Logo2 from "../../../public/assets/images/new_logo2.png";
import Routes from "../../routes/Routes";
const Logo = ({
  openPopup,
  popupValue,
  reinitSliderhome,
  setReinitSliderhome,
}) => {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const toggleInterval = setTimeout(() => {
      setToggle((toggle) => !toggle);
    }, 4000);

    return () => {
      toggleInterval && clearInterval(toggleInterval);
    };
  }, [toggle]);
  return (
    <div className="h_left_section">
      <Link
      href={`${Routes?.home}`}
      >
        <a
          className="logo_cb"
          onClick={() => setReinitSliderhome(!reinitSliderhome)}
        >
          <Image
            className="blink-logo"
            src={toggle ? Logo1.src : Logo2.src}
            alt="GO bermondsey uk"
            width={179}
            height={44}
          />
        </a>
      </Link>
      <div className="location_cb">
        <p onClick={() => openPopup(!popupValue)}>37 Tanner Street SE1 3LF</p>
      </div>
    </div>
  );
};

export default memo(Logo);
