import { put, takeLatest, call } from "redux-saga/effects";
import ACTIONS from "./WorkSpaceActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllWorkSpaceRequest = () => {
  return axios({
    method: "GET",
    url: AppUrl.WORKSPACE,
  });
};

const getWorkSpaceRequest = (id) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${id}`,
  });
};

function* getAllWorkSpace(action) {
  try {
    let workSpace = yield call(getAllWorkSpaceRequest);
    let data = workSpace?.data;
    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      yield put({ type: ACTIONS.GET_ALL_WORKSPACE_FAIL, payload: data?.meta });
    } else {
      yield put({
        type: ACTIONS.GET_ALL_WORKSPACE_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    console.log("get all workspace error", error);
  }
}

function* getWorkSpace(action) {
  try {
    let workSpace = yield call(getWorkSpaceRequest, action.payload);
    let data = workSpace?.data;
    data._id = action.payload;
    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      yield put({ type: ACTIONS.GET_WORKSPACE_FAIL, payload: data });
    } else {
      yield put({ type: ACTIONS.GET_WORKSPACE_SUCCESS, payload: data });
    }
  } catch (error) {
    console.log("get workspace error", error);
  }
}

// ACTION WATCHER
export default function* workSpaceSaga() {
  // user can logout without waiting for login action.
  yield takeLatest(ACTIONS.GET_ALL_WORKSPACE_REQUEST, getAllWorkSpace);
  yield takeLatest(ACTIONS.GET_WORKSPACE_REQUEST, getWorkSpace);
}
