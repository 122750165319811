import React, { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import Slider from "react-slick";
import blogImg_1 from "../../../../public/assets/images/blog-img.jpg";
/* import {RemoveString} from '../../../utils/CommonFunctions' */

const BlogList = ({ blogStyle, pictures = [] }) => {
  
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      adaptiveHeight: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },

        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      <Slider
        {...settings}
        className={`mc_row_new slider-cb-main ${blogStyle["slider-container"]}`}
      >
        {pictures?.sort((a, b) => a?.extra?.order - b?.extra?.order)?.map((pic) => {
          return(
            <div key={pic?._id} className={`${blogStyle["con_auto_50"]}`}>
              {/* <Link href=""> */}
              <div className={`${blogStyle["img-b-container"]}`}>
                {pic.extra.showCTAButton ? (
                  <a href={pic?.extra?.ctaBtnURL || ""} className={`${blogStyle["img-i-container"]}`} target="_blank" aa="fff">
                    <Image
                      src={pic?.url}
                      alt={pic?.tag}
                      width={388}
                      height={518}
                      priority={false}
                    />
                  </a>
                ) :
                  (
                    <div className={`${blogStyle["img-i-container"]}`}>
                      <Image
                        src={pic?.url}
                        alt={pic?.tag}
                        width={388}
                        height={518}
                        priority={false}
                      />
                    </div>
                  )}
              
                {/* <div className={`${blogStyle["img-i-container"]}`}>
                  <Image
                    src={pic?.url}
                    alt={pic?.tag}
                    width={388}
                    height={518}
                    priority={false}
                  />
                </div> */}
                <div className={`${blogStyle["blog-content"]}`}>
                  <span
                    dangerouslySetInnerHTML={{ __html: pic?.title }}
                  />

                  <div className="text-container"
                    dangerouslySetInnerHTML={{
                      __html: pic?.imgDescription,
                    }}
                  />
                  {/* <span>June 5, 2023</span>
                                <p>The Evolution of Eco-Consciousness ...</p> */}
                </div>
              </div>
              {/* </Link> */}
            </div>
          )
        }
        
        )}
      </Slider>
    </>
  );
};

export default BlogList;