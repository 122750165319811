import { HYDRATE } from "next-redux-wrapper";

import Actions from "./WorkSpaceActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  workSpaceMap: {},
  workSpaceList: [],
  activeWorkspaceList: [],
  loading: false,
  error: null,
  active: null,
  newlyCreated: null,
};

const workSpaceReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    case HYDRATE: {
      payload = payload.workspace;

      return Object.assign({}, state, {
        ...payload,
        workSpaceMap: Object.assign(
          {},
          state.workSpaceMap,
          payload.workSpaceMap
        ),
        workSpaceList: [
          ...new Set([...state.workSpaceList, ...payload.workSpaceList]),
        ],
        activeWorkspaceList: [
          ...new Set([
            ...state.activeWorkspaceList,
            ...payload.activeWorkspaceList,
          ]),
        ],
      });
    }

    /** fetch all workspaces start */

    case Actions.GET_ALL_WORKSPACE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Actions.GET_ALL_WORKSPACE_SUCCESS: {
      const allSpaces = action?.payload;

      return Object.assign({}, state, {
        workSpaceMap: Object.assign(
          {},
          state?.workSpaceMap,
          arrToObjMap(allSpaces)
        ),
        workSpaceList: [...new Set(getArrOrder(allSpaces))],
        activeWorkspaceList: [...new Set(getArrOrder(allSpaces, true))],
        loading: false,
      });
    }

    /** fetch all workspaces end */

    /** fetch one workspace start */

    case Actions.GET_WORKSPACE_REQUEST: {
      const spaceId = action?.payload;

      return Object.assign({}, state, {
        workSpaceMap: Object.assign({}, state.workSpaceMap, {
          [spaceId]: Object.assign({}, state.workSpaceMap?.[spaceId], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.GET_WORKSPACE_SUCCESS: {
      const space = action?.payload;
      return Object.assign({}, state, {
        workSpaceMap: Object.assign({}, state.workSpaceMap, {
          [space?._id]: space?.data,
        }),
        active: space?._id,
      });
    }

    case Actions.GET_WORKSPACE_FAIL: {
      const spaceId = action?.payload?._id;

      return Object.assign({}, state, {
        workSpaceMap: Object.assign({}, state.workSpaceMap, {
          [spaceId]: Object.assign({}, state.workSpaceMap?.[spaceId], {
            error: action?.payload,
            loading: false,
          }),
        }),
      });
    }

    /** fetch one workspace end */

    case Actions.SET_ACTIVE_WORKSPACE:
      return Object.assign({}, state, {
        active: action?.payload,
      });

    default:
      return state;
  }
};

export default workSpaceReducer;
