const { REACT_APP_BASE_URL } = process.env;
const BASE_URL = REACT_APP_BASE_URL;

const API_V1 = "/api/v1";
const API_BASE_URL = `${BASE_URL}/api/v1`;

const AppUrl = {
  BASE_URL: API_BASE_URL,
  WORKSPACE: API_V1 + "/space",
  DASHBOARD: API_BASE_URL + "/dashboard",
  BOOKING_TOUR: API_V1 + "/booking-tour",
  BOOKING_REQUEST: API_V1 + "/custom/bookings",
};

export default AppUrl;

