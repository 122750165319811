import React, { useCallback, useEffect } from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";

import { getAllWorkSpaceRequest } from "../../redux/workspace/WorkSpaceActions";

const Bookonlinebtn = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const activeWorkspace = useSelector(
    ({ workspace }) => workspace.activeWorkspaceList?.[0]
  );

  useEffect(() => {
    !activeWorkspace && dispatch(getAllWorkSpaceRequest());
  }, [activeWorkspace, dispatch]);

  const onNext = useCallback(() => {
    if (activeWorkspace) router.push(`/search?spaceId=${activeWorkspace}`);
  }, [activeWorkspace]);

  return (
    <>
      <div className="book_online_btn">
        <span onClick={onNext} className="btn_link">
          Book Online
        </span>
      </div>
    </>
  );
};

export default React.memo(Bookonlinebtn);
