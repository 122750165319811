import React, { useMemo } from "react";
import Link from "next/link";
import blogStyle from "../../../styles/sass/blog.module.scss";
import BlogList from "./BlogList";

const Blog = ({ data = {}, urlLink, bgColorValue }) => {
  const { name, description, pictures } = data;
  const urlData = urlLink && urlLink !== "" ? urlLink : 'https://go-bermondsey.co.uk/blog/';


  let bgColorData = bgColorValue ? "#"+bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }

  return (
    <>
      <section
        style={myStyle} 
        className={`mc_container_fluid p-spacerT p-spacerB ${blogStyle["blog-block"]}`}
      >
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className={`${blogStyle["con_auto"]}`}>
                <div className={`${blogStyle["setWidth"]}`}>
                  <div className="c-text-container">
                    <div
                      className="heading-title h-title"
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                    {/* <h3 className="heading-title h-title">Blog</h3> */}
                    {/* <p>
                      Welcome to our cutting-edge blog space, where we delve
                      into the dynamic world of contemporary ideas, industry
                      news and trending topics that redefine the way we work,
                      collaborate, and thrive. From tips on how to make the best
                      of shared spaces to inspirational articles on productivity
                      hacks, we have you covered.
                    </p> */}
                  </div>
                </div>
              </div>

              <div className={`${blogStyle["col_full"]}`}>
                <div className="mc_row_new">
                  {/* <Link href="/"> */}
                    <a href={urlData} className={`text-right ${blogStyle["link-text"]}`}>
                      View All
                    </a>
                  {/* </Link> */}
                </div>
                <BlogList blogStyle={blogStyle} pictures={pictures} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
