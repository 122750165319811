import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import ACTIONS from "./ResourcesActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllAvailableResourcesRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${params.space_id}/resources-availability`,
    params: params,
  });
};

function* getAllAvailableResources(action) {
  try {
    let resources = yield call(getAllAvailableResourcesRequest, action.payload);
    let _data = resources?.data;
    _data.filter = { ...(action.payload || {}) };
    delete _data?.filter?.offset;
    delete _data?.filter?.limit;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      // showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all booking resources error", error);
  }
}

// ACTION WATCHER
export default function* resourcesSaga() {
  // user can logout without waiting for login action.
  yield takeLatest(
    ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES,
    getAllAvailableResources
  );
}
