import React, { useMemo } from "react";
import Image from "next/image";
import profileImage1 from "../../../../public/assets/images/profile-image.jpg";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const TestimonialSlider = ({ testimonialsStyle, testimonials = [], testimonialContentData = [] }) => {
  /*  const sliderRef = useRef(null);
    useEffect(() => {
        sliderRef.current.slickGoTo(0);
    }, []); */
  
  const { cmsMap } = useSelector((state) => state?.cms);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      adaptiveHeight: true,
      initialSlide: 0,
    }),
    []
  );

  return (
    <>
      <div className="mc_row_new">
        <Slider
          {...settings}
          className={`slider-cb-main ${testimonialsStyle.t_slider}`}
        >
          {/* item code start */}
          {testimonials?.map((id, index) => {
            const mItem = cmsMap?.[id] ?? testimonialContentData[index];
            if (mItem) {
              return (
                <div key={id} className={`${testimonialsStyle.t_item}`}>
                  <div
                    className={`${testimonialsStyle.container_col} ${testimonialsStyle.con_auto}`}
                  >
                    <div className="text-center">
                      <span className={`${testimonialsStyle.circle_img}`}>
                        <Image
                          src={mItem?.pictures?.[0]?.url}
                          alt={mItem?.pictures?.[0]?.tag}
                          width={140}
                          height={140}
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    className={`${testimonialsStyle.container_col} ${testimonialsStyle.col_full}`}
                  >
                    <div className="">
                      <div className="c-text-container">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: mItem?.description,
                          }}
                        />
                        {/* <p>
                    I would recommend Car Repair Service to anyone without a
                    doubt! Very professional and reliable. The best customer
                    service and reasonable prices. My go to auto shop from now
                    on!!!
                  </p> */}
                        <div className="">
                          <div
                            className={`${testimonialsStyle.heading_title_default}`}
                            dangerouslySetInnerHTML={{ __html: mItem?.name }}
                          />

                          <div
                            dangerouslySetInnerHTML={{
                              __html: mItem?.meta?.role,
                            }}
                          />
                          {/* <p>Electrician</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}

          {/* item code end */}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialSlider;
