import React, {useEffect, useState} from 'react';
import Link from 'next/link';
import Image from 'next/image'
import Routes from '../../routes/Routes';
import facebookIcon from "../../../public/assets/images/facebook-icon.svg";
import instagramIcon from "../../../public/assets/images/insta-icon.svg";
import twitterIcon from "../../../public/assets/images/twitter-icon.svg";
import map from "../../../public/assets/images/footer-map.jpg";
import mapLarge from "../../../public/assets/images/map-large-image.svg";
import MapPopupBox from '../common-components/mappopup/MapPopupBox';
import botLogo from "../../../public/assets/images/bot/bot-logo.webp";
import botLogoTop from "../../../public/assets/images/bot/new_logo2.png";


const Footer = () => {
    const [mapData, setMapData] = useState(false);
    const [chat, setChat] = useState(true);
    const [chatClose, setChatClose] = useState(false);

  useEffect(() => {

  })

  const eventClosePopup = (e) => {
      document.body.classList.remove('scrollNone');
      setMapData(e)
    }

  const eventOpenPopup = (e) => {
      document.body.classList.add('scrollNone');
      setMapData(e)
    }

  const handleBot = () => {
    setChat(false)
    setChatClose(true)

  }

  const handleBotClose = () => {
    setChat(true)
    setChatClose(false)
  }

    return (
      <>
        {" "}
        <footer className="mc_container_fluid footer_outer_cb bg-black p-spacerT">
          <div className="mc_row">
            <div className="mc_container_inner">
              <div className="mc_row">
                <div className="container_col">
                  <div className="f-link-list">
                    <div className="title">Company</div>
                    <ul className="list-none f-link-text">
                      <li>
                        <Link href={Routes?.about_us}>About Us</Link>
                      </li>
                      <li>
                        <Link href={Routes?.blog}>Blogs</Link>
                      </li>
                      <li>
                        <Link href={Routes?.faqs}>FAQs</Link>
                      </li>
                      <li>
                        <Link href={{ pathname: `${Routes?.home}`, query: { screen: 'intro-video' }}}>Locate Us</Link>
                      </li>
                      <li>
                        <Link href={Routes?.book_a_visit}>Book a Visit</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="container_col">
                  <div className="f-link-list">
                    <div className="title">Work Spaces</div>
                    <ul className="list-none f-link-text">
                      <li>
                        <Link href={Routes?.office}>
                          Office Listing
                        </Link>
                      </li>
                      <li>
                        <Link href={Routes?.offices?.private_offices}>
                          Private Offices
                        </Link>
                      </li>

                      <li>
                        <Link href={Routes?.offices?.meeting_rooms}>
                          Meeting Rooms
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="container_col">
                  <div className="f-link-list">
                    <div className="title">Any questions? Call us</div>
                    <ul className="list-none f-link-text">
                      <li>
                        <Link href="mailto:+44 (0) 203 465 9123">+44 (0) 203 465 9123</Link>
                      </li>
                    </ul>
                    <ul className="list-none social_cb">
                      <li>
                        <a target='_blank' href={Routes?.social?.facebook}>
                            <Image
                              src={facebookIcon.src}
                              width={11}
                              height={19}
                              alt="Facebook"
                            />
                          </a>
                      </li>
                      <li>
                        <a target='_blank' href={Routes?.social?.twitter}>
                            <Image
                              src={twitterIcon.src}
                              width={18}
                              height={15}
                              alt="Twitter"
                            />
                        </a>
                      </li>
                      <li>
                        <a target='_blank' href={Routes?.social?.instagram}>
                          <Image
                              src={instagramIcon.src}
                              width={20}
                              height={20}
                              alt="Instagram"
                            />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="container_col">
                  <div className="f-link-list f-map" style={{"cursor":"pointer"}} onClick={()=>eventOpenPopup(!mapData)}>
                    <Image src={map} width={350} height={250} alt="Map" priority={false} />
                  </div>
                </div>
              </div>
              <div className="mc_row">
                <div className="container_col_12">
                  <div className="f-copyright">
                    <ul className='list-none'>
                      <li><Link href={Routes?.privacy_policy}>Privacy & Policy</Link></li>
                      <li><Link href={Routes?.cookies}>Cookies Policy</Link></li>
                    </ul>
                    <p>© 2024 GO Bermondsey. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      {/* map box */}
        <MapPopupBox imgUrl={mapLarge} eventClosePopup={eventClosePopup} mapData={mapData} />


        {/* <!-- bot code --> */}
        {/* <div id="body">
          <div id="chat-circle" onClick={()=>handleBot()} className={`${chat ? "showChat" :"hideChat"}`}>
            <span><img src={botLogo?.src} style={{ maxWidth: "90px" }} /></span>
          </div>

            <div className={`chat-box ${chatClose ? "showChat" :"hideChat"}`}>
                <div className="chat-box-header">
                    <span><img src={botLogoTop?.src} style={{maxWidth: "160px"}}/></span>
                    <span onClick={()=>handleBotClose()} className={`chat-box-toggle`}><i className="material-icons">close</i></span>
                </div>
                <div className="chat-box-body">
                    <iframe src="https://development.procezo.com/custom-bot-go?go-bermondsey@procezo.com" width="100%" height="500px"
                        frameBorder="0"></iframe>
                </div>
            </div>
        </div> */}
      </>
    );
}

export default Footer;
