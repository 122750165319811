import { put, takeEvery, call } from "redux-saga/effects";
import ACTIONS from "./CMSActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllCMSRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.DASHBOARD,
    params: params,
  });
};

function* getAllCMS(action) {
  try {
    let resources = yield call(getAllCMSRequest, action.payload);
    let _data = resources?.data;
    _data.isReset = !action.payload?.offset;

    delete action.payload?.offset;
    delete action.payload?.limit;
    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      yield put({
        type: ACTIONS.FETCH_ALL_CMS_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_CMS_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in cms fetch", error);
  }
}

// ACTION WATCHER
export default function* cmsSaga() {
  yield takeEvery(ACTIONS.FETCH_ALL_CMS, getAllCMS);
}
