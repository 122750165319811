import React from 'react'
import Link from 'next/link';
import Image from 'next/image';
import fullImg_1 from "../../../../../public/assets/images/full-img.jpg";



const ContentContainer = ({data = {}, bgColorValue}) => {
  const {
    name,
    description,
    pictures,
    meta: { showCTAButton, ctaButton } = {},
  } = data;
  
  let bgColorData = bgColorValue ? "#"+bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }

  return (
      <>
      {name && description && pictures?.length > 0 && (
        <section
          style={myStyle} 
          className={`mc_container_fluid p-spacerT p-spacerB content-outer-container`}
        >
          <div className="mc_row_new">
            <div className="mc_container_inner">
              <div className="mc_row_new">
                <div className={`mc_col_full`}>
                  <div className="c-text-container text-center">
                    {name && (
                      <div className="heading-title h-title" dangerouslySetInnerHTML={{ __html: name }} />
                    )}

                    {description && (
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    )}

                    {/* <h3 className="heading-title h-title">
                      Can't find what you're looking for?
                    </h3>
                    <p>
                      We provide private office space in a heritage building
                      that has been transformed into a coworking space.
                    </p> */}
                    {showCTAButton && (
                      <div className="btn-common">
                        <Link href={ctaButton?.ctaBtnURL}>
                          <a className="btn-primary text-uppercase">{ctaButton?.ctaBtnText}</a>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className="image-container">
                    <Image
                      src={pictures?.[0]?.url}
                      width={1140}
                      height={412}
                      priority={false}
                      alt={pictures?.[0]?.tag}
                    />
                  </div>
                </div>
              </div>

              {/* slider */}
            </div>
          </div>
        </section>
      )
   }
      </>
  );
};
 
export default ContentContainer;