import { HYDRATE } from "next-redux-wrapper";
import Actions from "./ResourcesActionTypes";

const initialState = {
  availableResourceMap: {},
  availableResources: {},
  filter: {},
};

const resourcesReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    case HYDRATE: {
      payload = payload.resources;

      return Object.assign({}, state, {
        ...payload,
        availableResourceMap: Object.assign(
          {},
          state.availableResourceMap,
          payload.availableResourceMap
        ),
        availableResources: Object.assign(
          {},
          state.availableResources,
          payload.availableResources
        ),
        filter: Object.assign({}, state.filter, payload.filter),
      });
    }

    /** get all booking resource start */
    case Actions.FETCH_ALL_AVAILABLE_RESOURCES: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: {
            error: null,
            loading: true,
          },
        }),
      });
    }

    case Actions.FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS: {
      const mFilter = JSON.stringify(payload?.filter);

      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: Object.assign({}, state.availableResources[mFilter], {
            list: payload?.data,
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_AVAILABLE_RESOURCES_FAILURE: {
      const mFilter = JSON.stringify(payload?.filter);

      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: Object.assign({}, state.availableResources[mFilter], {
            list: [],
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** get all booking resource end */

    default:
      return state;
  }
};

export default resourcesReducer;
