import React, { useMemo } from "react";
import { amenitieIconMap } from "../../../utils/AmenitieIcons";

const IconBox = ({ data = {} }) => {
  const {
    name,
    description,
    pictures,
    meta: { showCTAButton, ctaButton } = {},
  } = data;

  return (
    <>
      <section className={`mc_container_fluid p-spacerT p-spacerB icon-box-cb`}>
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className={`mc_col_full`}>
                <div className="c-text-container text-center">
                  {name && (
                    <div
                      className="heading-title h-title"
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                  )}

                  {description && (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  )}
                </div>
              </div>
            </div>

            <div className="mc_row_new">
              {pictures?.map((item, index) => (
                <div className={`mc_col_custom`} key={item?._id}>
                  <div className="icon-b-cb">
                    <span className="icon-img">
                      <img src={item?.url} />
                    </span>
                    <p>{item?.title?.replace(/(<([^>]+)>)/gi, "")}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IconBox;
