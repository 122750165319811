import React, { useCallback } from "react";
import Link from "next/link";
import { resourceTypes } from "../../utils/CommonFunctions";
// import { LinkedinFilled } from "@ant-design/icons";
import { useRouter } from "next/router";
import Routes from "../../routes/Routes";

const Submenu = ({
  openmenu,
  menuContainer,
  openPopup,
  popupValue,
  tourForm,
  tourFormToggle,
}) => {
  const router = useRouter();
  const closeMenu = () => menuContainer(false);
  const offerUrl = `${Routes?.offer}/64379a570f31814623c9600d`;

  const onSpecialOffer = useCallback(() => {
    router.push(offerUrl);
  }, [router]);


  return (
    <div
      className={openmenu ? "mc_sb_overlay activeNavbar" : "mc_sb_overlay"}
      onClick={closeMenu}
    >
      <div
        className="mc_sb_m_cb"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <span className="loc_close" onClick={closeMenu}>
          Close
        </span>
        <div className="mc_m_cb">
          <ul className="link_menu_cb v_mob_cb">
            {/* <li onClick={onSpecialOffer}>
              <span className="snake-border">
                <a href={offerUrl}>
                  <span />
                  <span />
                  <span />
                  <span />
                  Special Offer
                </a>

                <span className="placeholder">Special Offer</span>
              </span>
            </li> */}

            {Object.values(resourceTypes).map(
              (resourceType) =>
                resourceType.active && (
                  <li key={resourceType?._id}>
                    <Link 
                    href={`${Routes?.office}/${resourceType?._id}`}>
                      <a onClick={closeMenu}>{resourceType?.name}</a>
                    </Link>
                  </li>
                )
            )}
            <li>
              <Link href={Routes?.availability}>
                <a onClick={closeMenu}>Availability</a>
              </Link>
            </li>
          </ul>

          <ul className="mc_navigation">
            <li>
              <Link href={Routes?.home}>
                <a onClick={closeMenu}>Home</a>
              </Link>
            </li>
            <li>
              <Link href={Routes?.about_us}>
                <a onClick={closeMenu}>About Us</a>
              </Link>
            </li>

            <li>
              <Link href={Routes?.office}>
                <a className="desktop_active_link" onClick={closeMenu}>
                  Office Listing
                </a>
              </Link>
            </li>
            
             <li>
              <Link href={Routes?.office}>
                <a
                  className="mob_active_link"
                  onClick={() => {
                    //tourFormToggle();
                    closeMenu();
                  }}
                >
                  Office Listing
                </a>
              </Link>
            </li>
                
            
            <li>
              <Link href={Routes?.book_a_visit}>
                <a onClick={closeMenu}>Book a Visit</a>
              </Link>
            </li>

            <li>
              <Link href={Routes?.blog}>
                <a onClick={closeMenu}>Blog</a>
              </Link>
            </li>
            
            <li>
              <Link href={Routes?.faqs}>
                <a onClick={closeMenu}>FAQs</a>
              </Link>
            </li>
            {/* <li>
              <Link href="/our-locations" onClick={closeMenu}>
                Our Locations
              </Link>
            </li> */}
            {/* <li><Link href='/book-online' onClick={closeMenu}>Book Online</Link></li> */}
            
            

           

            {/* <li>
              <Link href="/our-spaces">
                <a onClick={closeMenu}>Our Spaces</a>
              </Link>
            </li> */}
            {/* <li>
              <Link href="/our-spaces">
                <a onClick={closeMenu}>Availability</a>
              </Link>
            </li> */}

            
            <li>
              {/* <span
                onClick={() => {
                  openPopup(!popupValue);
                  closeMenu();
                }}
              >
                Contact us
              </span> */}
              <Link href={Routes?.contact_us}>Contact Us</Link>
            </li>

            <li>
              <Link href={{ pathname: `${Routes?.home}`, query: { screen: 'intro-video' }}}>Locate Us</Link>
            </li>

            

            <li>
              <Link href={Routes?.privacy_policy}>
                <a onClick={closeMenu}>Privacy Policy</a>
              </Link>
            </li>

            
          </ul>

          <div className="bot_c_a_cb">
            <div className="mc_query">
              <p>Any questions? Call us</p>
              <a href="tel:+44 (0) 203 465 9123">+44 (0) 203 465 9123</a>
              <div className="mail_cb">
                <a href="mailto:info@go-bermondsey.co.uk">
                  info@go-bermondsey.co.uk
                </a>
              </div>
            </div>

            <div className="mc_social_link">
              <p>Follow Us</p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href={Routes?.social?.facebook}
                    className="fb_cb"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={Routes?.social?.twitter}
                    className="twitter_cb"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={Routes?.social?.instagram}
                    className="insta_cb"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={Routes?.social?.linkedin}
                    className="linkedin_cb"
                  >
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submenu;
