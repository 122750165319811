import { HYDRATE } from "next-redux-wrapper";

import Actions from "./CMSActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  cmsMap: {},
  boards: {},
  loading: false,
  error: null,
};

const cmsReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    case HYDRATE: {
      payload = payload.cms;

      return Object.assign({}, state, {
        ...payload,
        cmsMap: Object.assign({}, state.cmsMap, payload.cmsMap),
        boards: Object.assign({}, state.boards, payload.boards),
      });
    }

    /** get all cms start */
    case Actions.FETCH_ALL_CMS: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, state.boards?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_CMS_SUCCESS: {
      const mFilter = payload?.filter;
      const mBoard = state.boards?.[mFilter];

      let list = payload?.isReset ? [] : mBoard?.list || [];
      list = [...(list || []), ...getArrOrder(payload?.data, true)];

      return Object.assign({}, state, {
        cmsMap: Object.assign({}, state.cmsMap, arrToObjMap(payload?.data)),
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, mBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_CMS_FAILURE: {
      const mFilter = payload?.filter;

      return Object.assign({}, state, {
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, state.boards[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** get all cms end */

    default:
      return state;
  }
};

export default cmsReducer;
