import React, { useCallback, useMemo } from "react";
import Link from "next/link";
import { resourceTypes } from "../../utils/CommonFunctions";
import Submenu from "./Submenu";
import Bookonlinebtn from "../utils/Bookonlinebtn";
import { useRouter } from "next/router";
import Routes from "../../routes/Routes";

const Navbar = ({
  openmenu,
  menuContainer,
  openPopup,
  popupValue,
  tourForm,
  tourFormToggle,
  showLandingPage,
  setShowLandingPage
}) => {
  const router = useRouter();


  // for particular location show button 
  // const showBookingBtn = useMemo(() => {
  //   const allowedPaths = [
  //     "/",
  //     "/home",
  //     "/office-spaces",
  //     "/office-spaces/[deskName]",
  //   ];

  //   return allowedPaths.includes(router.pathname);
  // }, [router.route]);

  //const offerUrl = `${Routes?.offer}/64396b5b4283f109e1788e54`;
  const offerUrl = `${Routes?.offer}/64379a570f31814623c9600d`;
  const onSpecialOffer = useCallback(() => {
    
    router.push(offerUrl);
  }, [router]);

  return (
    <div className="h_right_section">
      <div className="hl_cb">
        <ul className="link_menu_cb d_hide">
          {/* <li onClick={onSpecialOffer}>
            <span className="snake-border">
              <a href={offerUrl}>
                <span />
                <span />
                <span />
                <span />
                Special Offer
              </a>

              <span className="placeholder">Special Offer</span>
            </span>
          </li> */}

          {Object.values(resourceTypes).map(
            (resourceType) =>
              resourceType.active && (
                <li key={resourceType?._id}>
                  <Link href={`${Routes?.office}/${resourceType?._id}`}>
                    {resourceType?.name}
                  </Link>
                </li>
              )
          )}
          <li>
            <Link href={Routes?.availability}>Availability</Link>
          </li>
          
          <li>
            {/* <span onClick={() => openPopup(!popupValue)}>Contact us</span> */}
            <Link href={Routes?.contact_us}>Contact Us</Link>
          </li> 
         {/*  <li>
            <Link href={Routes?.about_us}>About Us</Link>
          </li> */}
          {/* <li>
            <Link href={Routes?.faqs}>FAQs</Link>
          </li> */}
          {/* <li>
            <Link href={Routes?.blog}>Blog</Link>
          </li> */}
          <li>
            <Link href={Routes?.book_a_visit}>Book a Visit</Link>
          </li>

          <li>
            {/* <span onClick={() => setShowLandingPage(!showLandingPage)}>Our Locator</span> */}
            <Link href={{ pathname: `${Routes?.home}`, query: { screen: 'intro-video' }}}>Locate Us</Link>
          </li> 
        </ul>

        {
          // showBookingBtn &&
          // <div className="book_online_btn availability_btn">
          //   <Link href="/our-spaces">
          //     <a className="btn_link">Availability</a>
          //   </Link>
          // </div>
        }

        <div className="btn_mob_bo hide-desktop">
            <Bookonlinebtn />
          </div>

        {/* {showBookingBtn && (
          <div className="btn_mob_bo">
            <Bookonlinebtn />
          </div>
        )} */}

        <div
          className="h_hm_menu"
          /* className={openmenu ? "h_hm_menu activeMenu" : "h_hm_menu"} */
          onClick={() => menuContainer(!openmenu)}
        >
          <div className="framer-1"></div>
          <div className="framer-2"></div>
          <div className="framer-3"></div>
        </div>
        <Submenu
          openmenu={openmenu}
          menuContainer={menuContainer}
          openPopup={openPopup}
          popupValue={popupValue}
          tourForm={tourForm}
          tourFormToggle={tourFormToggle}
        />
      </div>
    </div>
  );
};

export default Navbar;
