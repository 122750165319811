const Routes = {
  domain:"https://go-bermondsey.co.uk",
  home: "/",
  home_page: "/home",
  about_us: "/about-us",
  office: "/office-spaces",
  our_spaces: "/our-spaces",
  offer:"/offer",
  offices:{
    private_offices:"/office-spaces/private-offices",
    meeting_rooms:"/office-spaces/meeting-rooms",
    /* dedicated_desks:"/office-spaces/dedicated-desks" */
  },
  availability: "/availability",
  faqs:"/faqs",
  blog:"/blog",
  book_a_visit:"/book-a-visit",
  privacy_policy:"/privacy-policy",
  notfound: "*",
  cookies: "/cookies",
  contact_us:"/contact-us",
  social:{
    facebook:"https://www.facebook.com/GoBermondsey",
    twitter:"https://twitter.com/Go_Bermondsey",
    instagram:"https://www.instagram.com/gobermondsey",
    linkedin:"https://www.linkedin.com/company/go-bermondsey"
  }
};
export default Routes;
