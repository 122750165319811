import React from 'react';

const Loading = () => {
    return (
        <>
            <div className='l_m_cb'>
                <div className='l_i_cb'>
                    <h5 className='loading_text'>Loading...</h5>
                    <div className="loading-spinner"></div>
                </div>
            </div>
        </>
    );
}

export default Loading;