import React from "react";
import Image from "next/image";
import Link from "next/link";
import Routes from "../../../routes/Routes";
import overview from "../../../../public/assets/images/overview.jpg";
import overviewStyle from "../../../styles/sass/overview.module.scss";

const Overview = ({ data = {}, orientation, bgColorValue }) => {
  const {
    name,
    description,
    pictures,
    meta: { showCTAButton, ctaButton } = {},
  } = data;

  let right = orientation ? orientation: "";
  let bgColorData = bgColorValue ? "#"+bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }

  return (
    <>
      {name && description && pictures?.length > 0 && (
        <section
          style={myStyle} 
          className={`mc_container_fluid p-spacerT p-spacerB ${overviewStyle["overview_cb"]} ` + (right ? ` ${overviewStyle["rightOrder"]}` : "")}  
        >
          <div className="mc_row_new"> 
            <div className="mc_container_inner">
              <div className="mc_row_new">
                {pictures?.[0]?.url && (
                  <div className={`${overviewStyle["con_auto"]} ` + (right ? `${overviewStyle["order-1"]}` : "")}>
                    <div className="text-center mb-3 mb-md-0">
                      <Image
                        src={pictures?.[0]?.url}
                        width={550}
                        height={500}
                        alt={pictures?.[0]?.tag}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={`g-d-flex g-align-items ${overviewStyle["col_full"]} ${overviewStyle["space_left"]} ` + (right ? `${overviewStyle["order-0"]}` : "")}
                >
                  <div className="c-text-container mob-text-center">
                    {name && (
                      <div
                        className="heading-title h-title"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                    )}

                    {/* <h3 className="heading-title h-title">Overview</h3> */}
                    {/* <p>
                      Housed within the warehouse building of the former Sarsons
                      vinegar brewery, Go Bermondsey brings to the market a
                      distinctive office space in London. 'The Garden Office'
                      has been reimagined to suit contemporary requirements.
                      Step into refreshingly airy, sun-lit working spaces that
                      will transform your work days into extraordinary
                      experiences.
                    </p> */}
                    {description && (
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    )}
                    {showCTAButton && (
                      <div className={`btn-common ${overviewStyle.pt_15}`}>
                        <Link href={ctaButton?.ctaBtnURL}>
                          <a className="btn-primary text-uppercase">
                            {ctaButton?.ctaBtnText}
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Overview;
