import React, { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import SliderImg_1 from "../../../../../public/assets/images/thumb-slider-img-1.jpg";
import arrowIcon from "../../../../../public/assets/images/arrow-icon-black.svg";
import sliderCategoryStyle from "../../../../styles/sass/slidercategory.module.scss";
import Slider from "react-slick";

const SliderCategory = ({ data = {} }) => {
  const { name, description, pictures } = data;

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      adaptiveHeight: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      <section
        className={`mc_container_fluid p-spacerT p-spacerB ${sliderCategoryStyle["slider-block"]}`}
      >
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className="col_full">
                <div className="c-text-container text-center">
                  <div
                    className="heading-title h-title"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                  {/* <h3 className="heading-title h-title">What you get</h3>
                    <p className={`${sliderCategoryStyle["maxWidth"]}`}>
                      Empowering Your Business, One Office at a Time. Experience
                      unrivalled comfort and convenience in our office rental
                      space.
                    </p> */}
                </div>
              </div>
            </div>

            {/* thumb-slider-img-1.jpg */}
            <div className="mc_row_new">
              <Slider
                {...settings}
                className={`slider-cb-main ${sliderCategoryStyle["inner-slider-cb"]}`}
              >
                {pictures?.sort((a, b) => a?.extra?.order - b?.extra?.order)?.map((pic) => {
                  
                  return(
                    <div
                      key={pic?._id}
                      className={`${sliderCategoryStyle["col_full_50"]}`}
                    >
                      <div className="mc_row_new">
                        <div className={`${sliderCategoryStyle["con_auto"]}`}>
                          <span className={`${sliderCategoryStyle["thumb_img"]}`}>
                            <Image
                              src={pic?.url}
                              width={263}
                              height={313}
                              alt={pic?.tag}
                              priority={false}
                            />
                          </span>
                        </div>
                        <div className={`${sliderCategoryStyle["col_full"]}`}>
                          <div className="c-text-container">
                            <div
                              className="heading-title h-title-3"
                              dangerouslySetInnerHTML={{ __html: pic?.title }}
                            />

                            <div
                              dangerouslySetInnerHTML={{
                                __html: pic?.imgDescription,
                              }}
                            />
                            {pic.extra.showCTAButton && (
                              <div className="btn_more_link_cb">
                              <Link href={pic?.extra?.ctaBtnURL || ""}>
                                <a
                                  className={`${sliderCategoryStyle["link-container"]}`}
                                >
                                  {pic?.extra?.ctaBtnText}
                                  <i>
                                    <Image src={arrowIcon.src} alt="arrow-icon"
                                      width={23} height={10} priority={false} />
                                  </i>
                                </a>
                              </Link>
                            </div>
                            )}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                
                }  )}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderCategory;
