import React, { useEffect, useState } from "react";
import TestimonialSlider from "./TestimonialSlider";
import testimonialsStyle from "../../../styles/sass/testimonials.module.scss";

const Testimonials = ({ data = {}, bgColorValue, testimonialContentData }) => {
  const { name, meta } = data;

  const [testimonials, setTestimonials] = useState([]);

 /*  useEffect(() => {
    setTestimonials(meta?.items);
  }, []); */

  let bgColorData = bgColorValue ? "#"+bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }

  return (
    <>
      <section
      style={myStyle}
        className={`mc_container_fluid p-spacerB p-spacerT ${testimonialsStyle.testimonials_cb}`}
      >
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className={`${testimonialsStyle.col_full}`}>
                <div className="c-text-container text-center">
                  <div
                    className="heading-title h-title"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                </div>
              </div>
            </div>

            {/* slider element */}
            <TestimonialSlider
              testimonialsStyle={testimonialsStyle}
              testimonials={meta?.items}
              testimonialContentData ={testimonialContentData}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
