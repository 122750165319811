import React, { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import Slider from "react-slick";

const CategorySliderList = ({ categorysliderStyle, pictures = [] }) => {
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      adaptiveHeight: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
          },
        },

        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      
      {pictures.length > 2 ? (
        <Slider
        {...settings}
        className={`mc_row_new slider-cb-main ${categorysliderStyle["slider-container"]}`}
      >
        {pictures?.map((pic) => (
          <div key={pic?._id} className={`${categorysliderStyle["con_box"]}`}>
              <a href={pic?.extra?.ctaBtnURL || ""} className={`${categorysliderStyle["img-b-container"]}`}>
                <div className={`${categorysliderStyle["img-i-container"]}`}>
                  <Image
                    src={pic?.url}
                    alt={pic?.tag}
                    width={360}
                    height={380}
                    priority={false}
                  />
                </div>
                <div className={`${categorysliderStyle["slider-content"]}`}>
                            <div className="c-text-container">
                                <div className={`heading-title h-title-3 ${categorysliderStyle["white"]}`} dangerouslySetInnerHTML={{ __html: pic?.title }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pic?.imgDescription,
                    }}
                  />
                   </div>
                 </div>
              </a>
          </div>
        ))}
      </Slider>
      ) : (
        <div className={`mc_row_new slider-cb-main ${categorysliderStyle["slider-container"]}  no-slider-content-center`}>
        {pictures?.map((pic) => (
          <div key={pic?._id} className={`${categorysliderStyle["con_box"]} no-slider`}>
            <Link  href={pic?.extra?.ctaBtnURL || ""}>
              <a className={`${categorysliderStyle["img-b-container"]}`}>
                <div className={`${categorysliderStyle["img-i-container"]}`}>
                  
                  <Image
                    src={pic?.url}
                    alt={pic?.tag}
                    width={360}
                    height={380}
                    priority={false}
                  />
                </div>
                <div className={`${categorysliderStyle["slider-content"]}`}>
                            <div className="c-text-container">
                                <div className={`heading-title h-title-3 ${categorysliderStyle["white"]}`} dangerouslySetInnerHTML={{ __html: pic?.title }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pic?.imgDescription,
                    }}
                  />
                   </div>         
                 </div>           
              </a>
            </Link>
          </div>
        ))}
      </div>
      ) }
      
    </>
  );
};

export default CategorySliderList;
