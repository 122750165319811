/* home page schema start */

export const homeWebPageSchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Foyr",
  "description": "GO Bermondsey provides a fully serviced office workspace in Bermondsey, London that suits all team sizes. Book a tour today and find your perfect business space.",
  "url": "https://go-bermondsey.co.uk/"
}
`;

export const homeOrganisationSchema = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "GO Garden Office Bermondsey",
  "alternateName": "GO Bermondsey",
  "url": "https://go-bermondsey.co.uk/",
  "logo": "https://go-bermondsey.co.uk/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnew_logo2.65a1ff00.png&w=256&q=75",
  "sameAs": [
    "https://www.facebook.com/GoBermondsey",
    "https://twitter.com/Go_Bermondsey",
    "https://www.instagram.com/gobermondsey",
    "https://www.linkedin.com/company/go-bermondsey"
  ]
}
`;

export const homeLocalBusinessSchema = `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "GO Garden Office Bermondsey",
  "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2Fxhoxfs1ukg6lw7nsxzj.png&w=640&q=75",
  "@id": "",
  "url": "https://go-bermondsey.co.uk/",
  "telephone": "+44 20 3465 9123",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "37 Tanner Street, Bermondsey St. Tower Bridge Rd",
    "addressLocality": "London",
    "postalCode": "SE1 3LF",
    "addressCountry": "GB"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 51.50031053938244,
    "longitude": -0.07949623232289876
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "08:00",
    "closes": "19:00"
  },
  "sameAs": [
    "https://www.facebook.com/GoBermondsey",
    "https://x.com/Go_Bermondsey",
    "https://www.instagram.com/gobermondsey",
    "https://www.linkedin.com/company/go-bermondsey/posts/?feedView=all"
  ]
}
`;

export const homeFAQBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Are all the workspaces furnished?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey's workspaces are fully furnished with modern elements. When outfitting these areas, the comfort of the employees and the aesthetic appeal of the workplace are both taken into consideration."
      }
    },
    {
      "@type": "Question",
      "name": "What are the different office sizes available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Whether you need a more compact workspace or a larger area for your bigger team, we have the perfect office for you in London to suit all your work requirements. You can get offices ranging in size from 500ft2 - 1350ft2."
      }
    },
    {
      "@type": "Question",
      "name": "Are GO Bermondsey offices private or shared?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our London workspace offers private offices and meeting rooms ideal for all solopreneurs to large teams with shared coworking lounges for your collaboration and networking needs."
      }
    },
    {
      "@type": "Question",
      "name": "What’s the speed of the internet at shared workspaces in London?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey office spaces offer you high-speed broadband internet, with speeds of up to 1 GB. We want to make sure your work day does not suffer due to unnecessary buffering issues."
      }
    },
    {
      "@type": "Question",
      "name": "What are the amenities that are offered in serviced offices in London?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey office space amenities include access to meeting rooms, office assistance, conference rooms, break rooms, recreational spaces, printing facilities, high-speed internet, kitchen facilities, and a secure parking area."
      }
    }
  ]
}
`;

/* home page schema end */

/* availability page schema start */

export const availabilityWebPageSchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "mainEntityOfPage": [
    {
      "@type": "WebPage",
      "name": "Viaduct Offices | Offices to Rent in Bermondsey for up to 16 users | GO (Garden Office) Bermondsey",
      "url": "https://go-bermondsey.co.uk/availability/6515877a641d1c54f44bd55b",
      "description": "Looking to rent a shared office space in London? GO Bermondsey’s Viaduct Offices are newly refurbished, and 675 sq. ft. private offices are available to rent. Enquire now!"
    },
    {
      "@type": "WebPage",
      "name": "Unit 10-10A | Offices to Rent in Bermondsey, London for up to 36 users | GO Bermondsey",
      "url": "https://go-bermondsey.co.uk/availability/65411fb36ff8f59614673462",
      "description": "Looking to rent an office space in Bermondsey, London? GO (Garden Office) Bermondsey’s Unit 10-10A are newly refurbished, and 1807 sq. ft. private offices are available to rent. Enquire now!"
    },
    {
      "@type": "WebPage",
      "name": "Unit 10 | Private Office to Rent in Bermondsey, London for up to 20 users | GO Bermondsey",
      "url": "https://go-bermondsey.co.uk/availability/654507c66ff8f5961467b836",
      "description": "Looking to rent a private office near London Bridge Station? Garden Office Bermondsey’s Unit 10 are newly refurbished, and 900 sq. ft. private offices are available to rent. Enquire now!"
    },
    {
      "@type": "WebPage",
      "name": "Unit 50A | Private Offices to Rent in Tanner Street, Bermondsey, London for up to 36 users | GO (Garden office) Bermondsey",
      "url": "https://go-bermondsey.co.uk/availability/66338bd6163994798d6384b2",
      "description": "Looking to rent a private office in Bermondsey, London? GO Bermondsey’s Unit 50A is just the right one for your business. This office is 470 sq. ft. private offices are available to rent and perfect for 6-8 users. Enquire now!"
    }
  ],
  "isPartOf": {
    "@type": "WebPage",
    "name": "GO (Garden Office) Bermondsey's Available Spaces",
    "url": "https://go-bermondsey.co.uk/availability"
  },
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How can I find available office space on GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Log in to our website at https://go-bermondsey.co.uk/ and select the 'Availability' option. A list of available office spaces for rent will appear. Choose the available office space that meets your requirements and click on the 'Book Tour' option. Fill in the required fields and submit the form, our team shall contact you. You could also email us at:- info@go-bermondsey.co.uk or call us at +44 (0) 203 465 9123."
        }
      },
      {
        "@type": "Question",
        "name": "What types of office spaces are available for rent on GO Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We offer a range of options tailored to support hybrid and flexible work patterns. Our commercial office spaces to rent include fully equipped private offices and meeting rooms for various team sizes. It is important to note that coworking spaces are available as part of an office rental package."
        }
      },
      {
        "@type": "Question",
        "name": "How do I enquire about an office space listed on GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Log in to our website at https://go-bermondsey.co.uk/ and click the 'Book Online' option. Fill in the required fields and a list of available offices will show up. Make a selection from the available office spaces for rent near London Bridge that meet your requirements. You will also find details such as floor plans, photos of the available London office spaces, and other essential information here. Go a step further and book yourself a visit to our offices."
        }
      },
      {
        "@type": "Question",
        "name": "Is GO (Garden Office) Bermondsey only for businesses looking to rent available office spaces?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey isn't just for businesses in search of office spaces to rent. Our London office spaces in Bermondsey offer a versatile environment that welcomes a broad spectrum of professionals and creatives. From freelancers and entrepreneurs to remote workers, startups, and artists, our coworking space provides an array of opportunities. Whether you're looking for a private office or meeting room, our offerings are designed to meet diverse needs. GO (Garden Office) Bermondsey is a place where collaboration flourishes, offering the ideal environment for your venture to grow."
        }
      },
      {
        "@type": "Question",
        "name": "What additional services or facilities are included with the rental?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When you book an office space for rent, you are offered additional services such as business support services and access to shared office space amenities such as the use of communal areas and recreational facilities. Other amenities include access to coworking spaces (when you book a private office), meeting rooms, office assistance, conference rooms, printing facilities, high-speed internet, kitchen facilities, printing and scanning services, and a secure parking area."
        }
      },
      {
        "@type": "Question",
        "name": "Can I customise the commercial space available for rent to suit the specific needs of my team or business?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, GO Bermondsey provides the flexibility to customise the available London office spaces according to your specific office requirements. Please reach out to our team, and they will discuss the customisation options with you. Feel free to call us at +44 (0) 203 465 9123 or email us at info@go-bermondsey.co.uk. We look forward to shaping your ideal workspace together!"
        }
      },
      {
        "@type": "Question",
        "name": "How do I find the best office space?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Finding the best office space to rent requires considering several factors such as location, size, amenities, and budget. At GO Bermondsey, we offer a diverse range of office spaces tailored to meet different needs. You can explore our options by logging into our website and clicking on the “Availability” option. This will present you with detailed information including floor plans, photos, and amenities, helping you make an informed decision."
        }
      },
      {
        "@type": "Question",
        "name": "What are the available office rental spaces in GO Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO Bermondsey offers a variety of office rental spaces designed to cater to various professional needs. Our offerings include fully equipped meeting rooms, private offices, shared desks, and coworking spaces. Each space is designed to support hybrid and flexible work patterns, suitable for freelancers, startups, and established businesses alike. For a detailed list of available office spaces to rent and their features, please visit the 'Availability' section on our website."
        }
      },
      {
        "@type": "Question",
        "name": "How much office space do I need?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The amount of office space you need depends on several factors, including the number of employees, your desired layout, and any specific requirements such as meeting rooms or private offices. We at GO (Garden Office) Bermondsey are happy to consult with you to determine the best fit for your team and business goals. Feel free to reach out to us directly for personalised advice.For more detailed information, including how to book a tour or discuss customization options, please contact us directly at +44 (0) 203 465 9123 or info@go-bermondsey.co.uk."
        }
      }
    ]
  }
}
`;

export const availabilityLocalBusinessSchema = `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "GO (Garden Office) Bermondsey",
  "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4gljadln31kx23.jpg&w=1200&q=75",
  "@id": "https://go-bermondsey.co.uk/",
  "url": "https://go-bermondsey.co.uk/",
  "telephone": "+44 20 3465 9123",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "37 Tanner Street SE1 3LF, Bermondsey St, Tower Bridge Rd,",
    "addressLocality": "London",
    "postalCode": "SE1 3JB",
    "addressCountry": "UK",
    "addressRegion": "GB"
  },
  "sameAs": [
    "https://www.facebook.com/GoBermondsey",
    "https://twitter.com/Go_Bermondsey",
    "https://www.instagram.com/gobermondsey/",
    "https://www.linkedin.com/company/go-bermondsey/"
  ],
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:30",
      "closes": "20:00"
    },
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Saturday",
        "Sunday"
      ],
      "opens": "09:00",
      "closes": "16:00"
    }
  ]
}
`;

export const availabilityBreadcrumbSchema = `
{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Home",
    "item": "https://go-bermondsey.co.uk/"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Availability",
    "item": "https://go-bermondsey.co.uk/availability"  
  }]
}
`;

/* availability page schema end */

/* private office page schema start */

export const privateOfficeWebPageSchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Private Office Space in Bermondsey, London",
  "url": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
  "description": "GO Bermondsey fully serviced office spaces in London are consciously designed with amenities to help you and your team focus on what's important—building your business.",
  "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://go-bermondsey.co.uk/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Office Spaces",
        "item": "https://go-bermondsey.co.uk/office-spaces"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Private Offices",
        "item": "https://go-bermondsey.co.uk/office-spaces/private-offices"
      }
    ]
  },
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Are the private office spaces furnished?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey's private offices are fully furnished, featuring a blend of historical and contemporary design elements. Besides that, our private workspaces are designed to provide ample natural light, greenery, and flexibility for modern professionals. We offer meeting spaces, office assistance, kitchen amenities, mail management, printing, scanning, and secure parking for your convenience. GO (Garden Office) Bermondsey is the perfect place for modern professionals to thrive."
        }
      },
      {
        "@type": "Question",
        "name": "What should I expect out of a GO (Garden Office) Bermondsey's Private Office Space?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You should expect a dedicated, closed-door space with ample seating and associated amenities - all of which are offered at GO Bermondsey. GO (Garden Office) Bermondsey offers fully furnished office spaces, including coworking areas, meeting rooms, dedicated desks, and private offices, all available at one convenient location."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any hidden costs associated with renting a small office space in GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, there are no hidden costs when renting an all-inclusive office with us. The option of a traditional let is also available."
        }
      },
      {
        "@type": "Question",
        "name": "What are the benefits of private office space in GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A private office space is meant to give you complete privacy to work, brainstorm, and collaborate. Other than a dedicated cabin, you also get access to a host of amenities with GO (Garden Office) Bermondsey."
        }
      },
      {
        "@type": "Question",
        "name": "How to book a private office space with GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "At GO (Garden Office) Bermondsey, making reservations for private offices is simple. Just follow the steps below: Click on the “Book Online” option at the bottom right corner.Fill in your details like name, contact number, accommodation type, work email, date, and time. Next, fill in, “How did you hear about us? Alternatively, you can write or call us at info@gomaltingsbermondsey.co.uk | +44 (0) 203 465 9123"
        }
      },
      {
        "@type": "Question",
        "name": "Can I customise my office space to let in Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, customisation of your office space is available with Landlord's prior agreement."
        }
      },
      {
        "@type": "Question",
        "name": "What types of businesses are suited for office space to let in Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our office spaces are suitable for many industries from creative, technology, and media to financial services and legal."
        }
      },
      {
        "@type": "Question",
        "name": "What is the capacity of your small office spaces in Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The capacity of our office spaces varies from 6 desks to 35 desks and benefits from a range of amenities."
        }
      }
    ]
  }
}
`;

export const privateOfficeServiceSchema = `
{
  "@context": "https://schema.org",
  "@type": "Service",
  "@id": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
  "sameAs": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
  "serviceType": "Private Office Spaces to Rent in Bermondsey, London",
  "areaServed": "Bermondsey, London, UK",
  "brand": "GO (Garden Office) Bermondsey",
  "description": "Great ideas require great office spaces to come to life. At GO (Garden Office) Bermondsey, we offer affordable and inspiring shared office spaces where innovation thrives.",
  "image": [
    "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=1200&q=75"
  ],
  "logo": "https://go-bermondsey.co.uk/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnew_logo1.4ec4a017.png&w=384&q=75",
  "offers": "Private Office Spaces in London to Rent",
  "potentialAction": "Book a Visit",
  "provider": "GO Bermondsey",
  "url": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
  "ReviewRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "5"
  },
  "Location": {
    "@type": "LocalBusiness",
    "name": "Private Office Spaces",
    "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
    "@id": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
    "url": "https://go-bermondsey.co.uk/office-spaces/private-offices/",
    "telephone": "+44 20 3465 9123",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "37 Tanner Street SE1 3LF, Bermondsey St, Tower Bridge Rd,",
      "addressLocality": "London",
      "postalCode": "SE1 3JB",
      "addressCountry": "UK",
      "addressRegion": "GB"
    },
    "sameAs": [
      "https://www.facebook.com/GoBermondsey",
      "https://twitter.com/Go_Bermondsey",
      "https://www.instagram.com/gobermondsey/",
      "https://www.linkedin.com/company/go-bermondsey/"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "08:30",
        "closes": "20:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Saturday",
          "Sunday"
        ],
        "opens": "09:00",
        "closes": "16:00"
      }
    ]
  }
}
  `;

/* private office page schema end */

/* meeting room page schema start */

export const meetingRoomServiceSchema = `
{
  "@context": "https://schema.org",
  "@type": "Service",
  "@id": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
  "sameAs": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
  "serviceType": "Meeting Rooms to Rent in Bermondsey, London",
  "areaServed": "Bermondsey, London, UK",
  "brand": "GO (Garden Office) Bermondsey",
  "description": "Book Meeting Rooms in London on an hourly basis at GO Bermondsey. Experience the versatile meeting spaces as per your needs. Schedule a tour now!!!",
  "image": [
    "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2Fxhoxfs1ukg6lw7ngrxc.png&w=1200&q=75"
  ],
  "logo": "https://go-bermondsey.co.uk/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnew_logo1.4ec4a017.png&w=384&q=75",
  "offers": "London Meeting Rooms near London Bridge Station to Rent",
  "potentialAction": "Book a Visit",
  "provider": "GO (Garden Office) Bermondsey",
  "url": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
  "ReviewRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "5"
  },
  "Location": {
    "@type": "LocalBusiness",
    "name": "Meeting Rooms",
    "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
    "@id": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
    "url": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
    "telephone": "+44 20 3465 9123",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "37 Tanner Street SE1 3LF, Bermondsey St, Tower Bridge Rd,",
      "addressLocality": "London",
      "postalCode": "SE1 3JB",
      "addressCountry": "UK",
      "addressRegion": "GB"
    },
    "sameAs": [
      "https://www.facebook.com/GoBermondsey",
      "https://twitter.com/Go_Bermondsey",
      "https://www.instagram.com/gobermondsey/",
      "https://www.linkedin.com/company/go-bermondsey/"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "08:30",
        "closes": "20:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Saturday",
          "Sunday"
        ],
        "opens": "09:00",
        "closes": "16:00"
      }
    ]
  }
}
`;

export const meetingRoomWebPageSchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Meeting Rooms to Rent in Bermondsey, London",
  "url": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/",
  "description": "Book Meeting Rooms in London on an hourly basis at GO Bermondsey. Experience the versatile meeting spaces as per your needs. Schedule a tour now!!!",
  "image": [
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/thumbnails/thumb_4gljadln1w42hm.png"
    },
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/thumbnails/thumb_4gljadln1w42nk.png"
    },
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/4gljadln1w4255.jpg"
    }
  ],
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://go-bermondsey.co.uk/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Office Spaces",
        "item": "https://go-bermondsey.co.uk/office-spaces/"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Meeting Rooms",
        "item": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms/"
      }
    ]
  },
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What types of meeting rooms do you offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We offer our clients meeting rooms of different sizes, from small spaces to larger boardrooms. Each room has been uniquely designed, and comes equipped with state-of-the-art technology to support your meeting requirements. We can also customise your meeting room according to your needs."
        }
      },
      {
        "@type": "Question",
        "name": "Are your meeting rooms equipped with audiovisual equipment?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, our meeting rooms offer you all the amenities you may require to facilitate your business meetings."
        }
      },
      {
        "@type": "Question",
        "name": "Can I book a meeting room for a few hours instead of a full day?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely, at GO (Garden Office) Bermondsey we recognise the fact that no two clients or companies are the same. Accordingly, we offer you flexible hiring options. Book our meeting rooms for a few hours or longer, we leave the choice to you."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any additional services available with the meeting rooms?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When you book our meeting rooms, you are also offered access to other member facilities like office assistance, break rooms, recreational spaces, printing and scanning facilities, high-speed internet, kitchen facilities, and a secure parking area."
        }
      },
      {
        "@type": "Question",
        "name": "Are there public transportation options near GO (Garden Office) Bermondsey?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our offices are conveniently located near the London Bridge tube station which lies in Zone 1 and is serviced by the Northern and Jubilee lines. London buses also service the area surrounding us."
        }
      },
      {
        "@type": "Question",
        "name": "Can I visit GO (Garden Office) Bermondsey to see the meeting rooms before making a booking?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Certainly, we understand the need to inspect a space before hiring. Log onto our website and we shall book an appointment for you to visit us. Explore our meeting rooms on offer before signing up."
        }
      },
      {
        "@type": "Question",
        "name": "How do I book a GO (Garden Office) Bermondsey meeting room?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To book a GO (Garden Office) Bermondsey meeting room you can request a meeting room via the website under the Meetings Rooms/Book Online link, email us at info@go-bermondsey.co.uk or call us at 0203 465 9123."
        }
      },
      {
        "@type": "Question",
        "name": "How do I modify or cancel a booking?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To modify or cancel a booking with GO (Garden Office) Bermondsey, email us at info@go-bermondsey.co.uk or call 0203 465 9123."
        }
      },
      {
        "@type": "Question",
        "name": "What happens if my meeting overruns or I need the room for longer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If your meeting runs longer than expected or you require the room for extended time, don't worry! We'll gladly accommodate you, provided the room is available. If not, we'll do our best to find an alternative space for you."
        }
      },
      {
        "@type": "Question",
        "name": "What is GO (Garden Office) Bermondsey's guest policy for meeting room bookings?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey doesn't have a strict guest policy for meeting room bookings. However, we need to know in advance the number of attendees for logistical purposes."
        }
      }
    ]
  }
}
`;

/* meeting room page schema end */


/* office space page schema start */

export const officeListingServiceSchema = `
{
  "@context": "https://schema.org",
  "@type": "Service",
  "@id": "https://go-bermondsey.co.uk/office-spaces/",
  "sameAs": "https://go-bermondsey.co.uk/office-spaces/",
  "serviceType": "Office Spaces to Rent in Bermondsey, London",
  "areaServed": "Bermondsey, London, UK",
  "brand": "GO (Garden Office) Bermondsey",
  "description": "Great ideas require great office spaces to come to life. At GO (Garden Office) Bermondsey, we offer affordable and inspiring shared office spaces where innovation thrives.",
  "image": [
    "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=1200&q=75"
  ],
  "logo": "https://go-bermondsey.co.uk/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnew_logo1.4ec4a017.png&w=384&q=75",
  "offers": "Office Spaces in London to Rent",
  "potentialAction": "Book a Visit",
  "provider": "GO Bermondsey",
  "url": "https://go-bermondsey.co.uk/office-spaces/",
  "ReviewRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "5"
  },
  "Location": {
    "@type": "LocalBusiness",
    "name": "Office Spaces",
    "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
    "@id": "https://go-bermondsey.co.uk/office-spaces",
    "url": "https://go-bermondsey.co.uk/office-spaces",
    "telephone": "+44 20 3465 9123",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "37 Tanner Street SE1 3LF, Bermondsey St, Tower Bridge Rd,",
      "addressLocality": "London",
      "postalCode": "SE1 3JB",
      "addressCountry": "UK",
      "addressRegion": "GB"
    },
    "sameAs": [
      "https://www.facebook.com/GoBermondsey",
      "https://twitter.com/Go_Bermondsey",
      "https://www.instagram.com/gobermondsey/",
      "https://www.linkedin.com/company/go-bermondsey/"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "08:30",
        "closes": "20:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Saturday",
          "Sunday"
        ],
        "opens": "09:00",
        "closes": "16:00"
      }
    ]
  }
}`;

export const officeListingWebPageSchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Office Space in London",
  "url": "https://go-bermondsey.co.uk/office-spaces",
  "description": "GO Bermondsey fully serviced office spaces in London are consciously designed with amenities to help you and your team focus on what's important—building your business.",
  "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://go-bermondsey.co.uk/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Office Spaces",
        "item": "https://go-bermondsey.co.uk/office-spaces"
      }
    ]
  },
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How to rent office space in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Renting office space in London is easy. Visit GO (Garden Office) Bermondsey's website and follow the steps below. Click on the 'Book Now' option.Choose the office space you want. Fill in your details: name, contact number, accommodation type, work email, date, and time. Next, fill in, 'How did you hear about us?'. Alternatively, you can write or call us at info@go-bermondsey.co.uk | +44 (0) 203 465 9123"
        }
      },
      {
        "@type": "Question",
        "name": "Does GO (Garden Office) Bermondsey offer office spaces in the City of London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, GO (Garden Office) Bermondsey's fully serviced office spaces are conveniently located on Tanner Street in Bermondsey, just a 7-minute walk from London Bridge Station."
        }
      },
      {
        "@type": "Question",
        "name": "What are the different types of office spaces available in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey offers coworking workspaces that foster collaboration and innovation. We provide private office spaces, meeting rooms, and dedicated desks to meet all your work requirements."
        }
      },
      {
        "@type": "Question",
        "name": "What factors should I consider when choosing office space in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When choosing a shared office space in London, consider the location, amenities, technical support, and furnishings. GO (Garden Office) Bermondsey offers a workspace that is fully furnished and equipped with everything you need to work smoothly. Also, our shared office spaces are easily accessible from the London Bridge station, making commuting easy."
        }
      },
      {
        "@type": "Question",
        "name": "Why should we choose GO (Garden Office) Bermondsey for shared office spaces?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey, a leading office rental and coworking space provider aims to create a fully serviced office community. Our shared office spaces are a flawless fusion of traditional and modern design, which sets us apart. Besides, our workspace is located at a prime location that connects you with the thriving business landscape in London."
        }
      },
      {
        "@type": "Question",
        "name": "How can I find commercial office spaces to rent?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey offers commercial office spaces to rent in London at an affordable price. Our office spaces have everything to streamline all your work needs, from essential furnishings to whiteboards and audio-visual needs."
        }
      },
      {
        "@type": "Question",
        "name": "What is the cost of renting an office space in GO (Garden Office) Bermondsey, London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The cost of renting an office space at GO (Garden Office) Bermondsey varies depending on your required office space. Private office rent starts from £6,000 pcm for 12 desks and goes up to £16,950 pcm for 30 desks. The Viaduct Offices rent starts at £7,800 + VAT pcm. Unit 10 - 10A office space costs £149 per square foot."
        }
      },
      {
        "@type": "Question",
        "name": "Can I rent office space in GO (Garden Office) Bermondsey London for one day or just a few days?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can rent an office space in GO (Garden Office) Bermondsey, London, for a day or a few days and gain access to the premium amenities provided to all members. Contact the GO (Garden Office) Bermondsey team for more details."
        }
      },
      {
        "@type": "Question",
        "name": "What services are included in serviced offices in GO (Garden Office) Bermondsey, London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO (Garden Office) Bermondsey's serviced offices in London offer a wide range of amenities, including access to dedicated meeting rooms, conference rooms, office assistance, break-out rooms, high-speed internet, printing facilities, kitchen facilities, and a secure parking area. These services are intended to improve your work experience and productivity within the office space."
        }
      }
    ]
  }
}
`;



/* office space page schema start */

export const faqsBodySchema = `[
    {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Office Space Rental FAQs | GO Bermondsey",
        "url": "https://go-bermondsey.co.uk/faqs",
        "description": "Seeking to rent office space in Bermondsey, London? Discover costs and see if it suits you with our FAQ on office rentals.",
        "publisher": {
            "@type": "WebPage",
            "name": "Go-Bermondsey"
        }
    },
    {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": {
            "@type": "Question",
            "name": "What are the average rental costs for office space in GO - Garden Office Bermondsey, London?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The average rental costs for office spaces at GO - Garden Office Bermondsey, London, vary depending on the size and type of workspace you select. The monthly cost of private office space ranges from £6000 per month for 12 desks to £16950 per month for 30 desks."
            }
        }
    },
    {
        "@type": "Question",
        "name": "What businesses use GO Bermondsey Coworking Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO Bermondsey coworking space offers top-notch shared amenities to all kinds of businesses - small, medium, or large. From startups and freelancers to large businesses with remote workers, some of the fastest-growing brands are operating from GO Bermondsey."
        }
    },
    {
        "@type": "Question",
        "name": "Is GO Bermondsey good for networking?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Many startups and emerging businesses operate out of GO Bermondsey, a place where they can interact with new people, gain experiences, and thrive. It encourages networking and collaboration opportunities and helps businesses to expand their brands."
        }
    },
    {
        "@type": "Question",
        "name": "What makes GO Bermondsey Coworking Space different?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO Bermondsey, a leading office rental and coworking space provider, aims to create a fully serviced office community. Their unique approach to the workplace—a flawless fusion of traditional and modern design — sets them apart."
        }
    },
    {
        "@type": "Question",
        "name": "What are your opening and closing hours?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "We are open Monday through Friday from 8 am to 5 pm."
        }
    },
    {
        "@type": "Question",
        "name": "Can I invite clients or guests?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can invite clients or guests if you have reserved a private office or meeting room at the GO Bermondsey coworking space."
        }
    },
    {
        "@type": "Question",
        "name": "Can I choose to use the space for the day?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can reserve a day pass to gain access to the premium amenities available to all members."
        }
    },
    {
        "@type": "Question",
        "name": "Can I bring my pet in?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, pets are allowed at the GO Bermondsey rental and coworking space. They must be friendly, well-mannered and get along well with other pets and members."
        }
    },
    {
        "@type": "Question",
        "name": "Can I use GO Bermondsey as my registered business address?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, if you have a tenancy with us, you can register your business at Maltings."
        }
    },
    {
        "@type": "Question",
        "name": "Is the GO Bermondsey Coworking Space green?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, GO Bermondsey rental and coworking office spaces are located within a fully enclosed, private garden oasis in Maltings. It is surrounded by hanging plants and illuminated by natural light."
        }
    },
    {
        "@type": "Question",
        "name": "Do you have a Reception / Concierge?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, we have a reception that is open all day to offer assistance. After regular business hours, the front desk is staffed by security."
        }
    },
    {
        "@type": "Question",
        "name": "What about deliveries?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Deliveries can be accepted and held at the front desk for later pickup."
        }
    },
    {
        "@type": "Question",
        "name": "Is there car parking?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, we offer flexible parking options, including for visitors, at Maltings."
        }
    },
    {
        "@type": "Question",
        "name": "Are there additional costs involved in renting an office space beyond the rent?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The total cost of renting office space at GO - Garden Office Bermondsey includes a range of amenities, such as high-speed internet access, kitchen amenities, cleaning services, office assistance, and more. So, you don’t have to worry about paying any additional fees."
        }
    },
    {
        "@type": "Question",
        "name": "What lease terms are generally available for office spaces in Bermondsey?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO - Garden Office Bermondsey offers a 12-month rolling agreement for office spaces so that you don’t have to commit to a longer term."
        }
    },
    {
        "@type": "Question",
        "name": "What types of office layouts are available?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO - Garden Office Bermondsey offers two types of office layouts to cater to various business requirements: private offices and meeting rooms."
        }
    },
    {
        "@type": "Question",
        "name": "What kind of amenities can I expect with office spaces in Bermondsey?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO - Garden Office Bermondsey office spaces provide a range of amenities, including access to dedicated meeting rooms, high-speed internet, office assistance, cleaning services, kitchen amenities, break-out areas, secure parking facilities, and more."
        }
    },
    {
        "@type": "Question",
        "name": "How do I know if Bermondsey is the right location for my business?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Bermondsey is a vibrant area with a rich history and a thriving business community. It offers easy access to the City, Waterloo, and Westminster, along with cutting-edge tech infrastructure and flexible space options, making it a prime location for many businesses."
        }
    },
    {
        "@type": "Question",
        "name": "What should I look for during an office space visit at GO - Garden Office Bermondsey?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "When scheduling an office space visit at GO - Garden Office Bermondsey, you should consider key factors, such as workspace layout, meeting rooms, internet connectivity, furniture and amenities, networking opportunities, privacy, reception services, breakout areas, parking facilities, and overall atmosphere."
        }
    },
    {
        "@type": "Question",
        "name": "Is there room for expansion in GO - Garden Office Bermondsey office spaces?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our office spaces can accommodate anywhere from 12 desks to 30 desks, so you can easily upgrade to a larger workspace as your business grows."
        }
    },
    {
        "@type": "Question",
        "name": "Can I customise or renovate the office space to fit my business needs?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can customise or renovate the office space at GO - Garden Office Bermondsey to meet your unique business requirements. Our dedicated team will help you customise the workspace according to your needs."
        }
    },
    {
        "@type": "Question",
        "name": "Is there a deposit required, and if so, how much?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, a security deposit is required.  This is usually the equivalent to 3 months’ rent and is to be paid before the start of the lease agreement."
        }
    },
    {
        "@type": "Question",
        "name": "What is the policy on subleasing the space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Subletting is not allowed. If you wish to share an office with another company, please discuss this with the GO - Garden Office Bermondsey team."
        }
    },
    {
        "@type": "Question",
        "name": "How accessible is the office outside regular business hours?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Depending on the agreed terms, up to 24/7 access is available.  The security team remains on site outside of regular working hours."
        }
    },
    {
        "@type": "Question",
        "name": "What security measures are in place (CCTV, security personnel, entry systems)?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO - Garden Office Bermondsey prioritises the safety and security of its members. To guarantee a safe working environment, we have CCTV surveillance in place and security personnel on duty."
        }
    },
    {
        "@type": "Question",
        "name": "How is the office cleaned and maintained, and who is responsible for these services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our dedicated cleaning staff at GO - Garden Office Bermondsey ensures regular office cleaning and maintenance. The cleaning services are included in the rental price to provide a seamless and comfortable working environment."
        }
    },
    {
        "@type": "Question",
        "name": "Are there any restrictions on signage or branding in the office or building exterior?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GO-Garden Office Bermondsey will work with you on your internal branding requirements. External signage, with your company name on it, will be mounted for you when you move in."
        }
    },
    {
        "@type": "Question",
        "name": "How many tables are available in Private offices?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Private offices are furnished with a table for each desk. The number of tables available varies based on the size of the office you select."
        }
    },
    {
        "@type": "Question",
        "name": "How big are your office meeting rooms?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "We offer meeting rooms of various sizes and configurations. Each room has a unique design and is equipped with cutting-edge technology to match your meeting requirements."
        }
    },
    {
        "@type": "Question",
        "name": "How can I maintain the privacy of my business when renting office space in GO - Garden Office Bermondsey?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "To maintain the privacy of your business, you can choose a private office at GO-Garden Office Bermondsey. Private offices have lockable spaces that offer an ideal environment for focused work and confidentiality of your business operations."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Private Office?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A private office is a designated workspace reserved for an individual or a small team. It offers an ideal environment for concentrated work, meetings, or private conversations."
        }
    },
    {
        "@type": "Question",
        "name": "What are Meeting Rooms?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Meeting rooms are designated spaces in an office where teams can gather, discuss, and collaborate on projects and presentations."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Huddle Room?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A huddle room is a small, tech-enabled collaborative room designed for quick team meetings and discussions."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Conference Room?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A conference room is a designated space in an office or building for events, such as business conference calls and meetings."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Flexible Office Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Flexible office space refers to a dynamic workspace solution that enables businesses to rent or use office space on a short-term or on an as-needed basis, catering to changing needs and requirements."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Shared Office Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A shared office space is a flexible workspace where individuals or companies share resources and amenities and often work together in a group setting."
        }
    },
    {
        "@type": "Question",
        "name": "What is Desk Sharing?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Desk sharing is a flexible seating arrangement in which employees do not have designated or permanent desks; instead, they use any available desk as and when needed."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Modern Office Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A modern office space is a dynamic workspace designed to promote innovation and productivity through collaboration, flexibility, and technology integration."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Dedicated Desk?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A dedicated desk is a single desk in a shared office environment rented to an individual who has access to shared professional amenities."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Flexi Desk?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A flexi desk is a flexible workspace solution that allows individuals or teams to use desks temporarily or as needed within a shared office environment."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Managed Office Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A managed office space is a fully furnished workspace managed by a third-party provider. It offers businesses flexible workspace solutions without the hassle of maintenance or administrative tasks."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Board Room?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A boardroom is a space within an organisation where the board of directors, or others, meet to discuss business matters and make strategic decisions."
        }
    },
    {
        "@type": "Question",
        "name": "What is Office Space Planning?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Office space planning is the process of designing and organising work areas to maximise employees’ productivity, well-being, and functionality."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Dynamic Office Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A dynamic office space is a flexible workspace that adapts to the evolving needs and preferences of employees, promoting collaboration, innovation, and productivity."
        }
    },
    {
        "@type": "Question",
        "name": "What is a Co-working Space?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A co-working space is a shared office environment where freelancers or individuals from different companies work alongside each other, sharing facilities and amenities."
        }
    }
]`;
