import React from "react";
import Header from "./Header";
import { useRouter } from "next/router";
import Footer from "../footer/Footer";
export default function Layout({
  children,
  popup,
  openPopup,
  closePopup,
  tourForm,
  tourFormToggle,
  reinitSliderhome,
  setReinitSliderhome,
}) {
  const router = useRouter();
  const { pathname } = router;
  const HeaderValue = () => {
    return (
      <>
        <Header
          popup={popup}
          openPopup={openPopup}
          closePopup={closePopup}
          tourForm={tourForm}
          tourFormToggle={tourFormToggle}
          reinitSliderhome={reinitSliderhome}
          setReinitSliderhome={setReinitSliderhome}
        />
        {children}
        
        <Footer/>
      </>
    );
  };
  return <> {pathname !== "/" ? <HeaderValue /> : children} </>;
}
