import React from "react";
import Link from "next/link";
import Image from "next/image";
const Locationpopup = ({ mapImage, popupValue, closePopup }) => {

  return (
    <>
      <div
        className={popupValue ? "loc_popup_o_cb close_popup" : "loc_popup_o_cb"} onClick={() => closePopup(!popupValue)}
      >
        <div className="loc_popup_i_cb">
          <span className="loc_close_container">
            <span className="loc_close" onClick={() => closePopup(!popupValue)}>
              Close
            </span>
          </span>
          <Image 
          src={mapImage} 
          alt="Map" 
          className="full-image"
          width={640}
          height={580}
          
          onClick={(event) => {
              event.stopPropagation();
            }} />
          <div className="loc_popup_content_cb" onClick={(event) => {
              event.stopPropagation();
            }}>
            <ul>
              <li className="location_icon">
                <span>
                <a
                    href="https://goo.gl/maps/BhBXdndipqyBBJBQ7"
                    target="_blank"
                  >
                  37 Tanner Street SE1 3LF(off Bermondsey Street) and Tower
                  Bridge Road SE1 3JB
                  </a>
                </span>
              </li>
              <li className="telephone_icon">
                <a href="tel:+44 (0) 203 465 9123">+44 (0) 203 465 9123</a>
              </li>
              <li className="mail_icon">
                <a href="mailto:info@go-bermondsey.co.uk">
                  info@go-bermondsey.co.uk
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locationpopup;
