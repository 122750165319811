import { all } from "redux-saga/effects";
import workspaceSaga from "../redux/workspace/WorkSpaceSaga";
import cmsSaga from "../redux/cms/CMSSaga";
import resourcesSaga from "../redux/resources/ResourcesSaga";

function* RootSaga() {
  yield all([workspaceSaga(), cmsSaga(), resourcesSaga()]);
}

export { RootSaga };
