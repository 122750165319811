import React, {useMemo} from "react";
import Link from "next/link";
import Image from "next/image";
import Slider from "react-slick";
import sliderImg from '../../../../../public/assets/images/thumb-img-slider-1.jpg';


const BenefitsContainer = ({ data= {}}) => {

  const {
    name,
    description,
    pictures,
    meta: { showCTAButton, ctaButton } = {},
  } = data;


  
   const settings = useMemo(
        () => ({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true ,
        arrows: false,
        responsive: [
        {
        breakpoint: 991,
        settings: {
            slidesToShow:3
        }
        },

        {
        breakpoint: 767,
        settings: {
            slidesToShow:2
        }
            },
        {
        breakpoint: 480,
        settings: {
            slidesToShow:1
        }
        }
    ]
        }),
        []
    );

  return (
    <>
        <section
          className={`mc_container_fluid p-spacerT p-spacerB benefit-outer-container`}
        >
          <div className="mc_row_new">
            <div className="mc_container_inner">
              <div className="mc_row_new">
                <div className={`mc_col_full`}>
                  <div className="c-text-container text-center">
                    {name && (
                      <div
                        className="heading-title h-title"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                    )}

                    {description && (
                      <div dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}

                    {/* <div className="heading-title h-title">
                      Benefits of GO Bermondsey Private Offices
                    </div> */}
                    {/* <p>
                      It will foster your development and well-being, leading to
                      more content and fulfilling work life.
                    </p> */}
                  </div>
                </div>
            </div>
            {/*  */}
            {/* <div className="mc_row"> */}
            <Slider {...settings} className={"mc_row new slider-img-cb"}> 
              
              {pictures?.sort((a, b) => a?.extra?.order - b?.extra?.order)?.map((pic) => (
              <div  key={pic?._id} id={pic?._id} className="slider-t-o-cb">
                    <div className="slider-t-i-cb">
                      {pic?.thumb_location && (
                        <div className="slider-t-i-img-cb">
                        <Image src={pic?.thumb_location}
                          width={0}
                          height={0}
                          sizes="100vw"
                          alt={pic?.tag} />
                      </div>
                      )
                    }
                     {pic?.imgDescription && (
                      <div dangerouslySetInnerHTML={{ __html: pic?.imgDescription }}
                      />
                    )}
                              
                    </div>
                </div>
              ))
            }
                
                {/* <div className="slider-t-o-cb">
                    <div className="slider-t-i-cb">
                     <div className="slider-t-i-img-cb">
                       <Image src={sliderImg.src} 
                        width={0} 
                        height={0}
                        sizes="100vw" 
                        alt="" />
                     </div>
                        <p>Perfect for a private meeting in a professional setting</p>                 
                    </div>
                </div>


                <div className="slider-t-o-cb">
                    <div className="slider-t-i-cb">
                     <div className="slider-t-i-img-cb">
                       <Image src={sliderImg.src} 
                        width={0} 
                        height={0}
                        sizes="100vw" 
                        alt="" />
                     </div>
                        <p>Perfect for a private meeting in a professional setting</p>                 
                    </div>
                </div>


                <div className="slider-t-o-cb">
                    <div className="slider-t-i-cb">
                     <div className="slider-t-i-img-cb">
                       <Image src={sliderImg.src} 
                        width={0} 
                        height={0}
                        sizes="100vw" 
                        alt="" />
                     </div>
                        <p>Perfect for a private meeting in a professional setting</p>                 
                    </div>
                </div>

                <div className="slider-t-o-cb">
                    <div className="slider-t-i-cb">
                     <div className="slider-t-i-img-cb">
                       <Image src={sliderImg.src} 
                        width={0} 
                        height={0}
                        sizes="100vw" 
                        alt="" />
                     </div>
                        <p>Perfect for a private meeting in a professional setting</p>                 
                    </div>
                </div>*/}

                </Slider> 
            {/* </div>    */}           

            </div>
          </div>
        </section>
    </>
  );
};

export default BenefitsContainer;
