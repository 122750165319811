import { combineReducers } from "redux";
import workspaceReducer from "../redux/workspace/WorkSpaceReducer";
import cmsReducer from "../redux/cms/CMSReducer";
import resourcesReducer from "../redux/resources/ResourcesReducer";
import { HYDRATE } from "next-redux-wrapper";

resourcesReducer;

const appReducer = combineReducers({
  workspace: workspaceReducer,
  cms: cmsReducer,
  resources: resourcesReducer,
});

const RootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    // state = Object.assign({}, state, action.payload);
  }
  // clearing redux state when user logs out
  else if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default RootReducer;
