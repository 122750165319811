

import React, { useState} from 'react'
import faqsStyle from "../../../styles/sass/faqs.module.scss";

const FaqListing = ({title, description}) => {
  const [show, setShow] = useState(false);
  const accordionHandle = () => {
    setShow(!show);
  }
  return (
    <>
      <div className="mc_row_new">
        <div className={`${faqsStyle["faq-container-inner"]}`}>
          <div  className={`${faqsStyle["faq-inner"]} ` + (show === true ? `${faqsStyle["activeFaq"]}` : "")}>
            <div className="c-text-container">
              <div
                className={`${faqsStyle["heading_title_default"]}`} onClick={accordionHandle}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              
              <div className={`${faqsStyle["faq-description"]}`} dangerouslySetInnerHTML={{ __html: description }}  />
        
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqListing;
