import Actions from './WorkSpaceActionTypes'

export const workSpaceAddRequest = ( params = {} ) => {
    return {
        type: Actions.ADD_WORKSPACE_REQUEST,
        payload: params
    }
}

export const updateWorkSpace = ( params = {} ) => {
    return {
        type: Actions.UPDATE_WORKSPACE_REQUEST,
        payload: params
    }
}

export const getAllWorkSpaceRequest = () => {
    return {
        type: Actions.GET_ALL_WORKSPACE_REQUEST
    }
}

export const getWorkSpaceRequest = (id) => {
    return {
        type: Actions.GET_WORKSPACE_REQUEST,
        payload: id,
    }
}

export const setActiveWorkSpace = (id) => {
    return {
        type: Actions.SET_ACTIVE_WORKSPACE,
        payload: id
    }
}
