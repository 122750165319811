import React, { useState } from "react";
import locationImage from "../../../public/assets/images/map.jpg";
import Logo from "./Logo";
/* import "../../assets/sass/header.scss"; */
import Navbar from "./Navbar";
import Locationpopup from "../utils/Locationpopup";

const Header = ({tourForm, tourFormToggle, reinitSliderhome, setReinitSliderhome, showLandingPage, setShowLandingPage }) => {
  /* const [popup, setPopup] = useState(false); */
  const [openmenu, setOpenmenu] = useState(false);
  const [popup, setPopup] = useState(false);

  let menuContainer = (e) => {
    setOpenmenu(e);
    document.body.classList.toggle("navbar_toggle");
  };

  let openPopup = (e) => {
        setPopup(e);
    }

    let closePopup = (e) => {
        setPopup(e);
    }

    
  return (
    <>
      {/* blk_c_t_cb */}
      <div id="mainHeader" className="mc_container_fluid mc_ps_cb">
        <div className="mc_row">
          <div className="mc_container">
            <div className="mc_row spc_between">
              <Logo
                popupValue={popup}
                openPopup={openPopup}
                reinitSliderhome={reinitSliderhome}
                setReinitSliderhome={setReinitSliderhome}
              />
              <Navbar
                popupValue={popup}
                openPopup={openPopup}
                openmenu={openmenu}
                menuContainer={menuContainer}
                tourForm={tourForm}
                tourFormToggle={tourFormToggle}
                showLandingPage={showLandingPage}
                setShowLandingPage={setShowLandingPage}
              />
            </div>
          </div>
        </div>
      </div>

      <Locationpopup
        mapImage={locationImage.src}
        popupValue={popup}
        closePopup={closePopup}
      />
    </>
  );
};
export default Header;
