import React, { useEffect, useState } from "react";
import categorysliderStyle from "../../../styles/sass/categoryslider.module.scss";
import CategorySliderList from "./CategorySliderList";

const CategorySlider = ({ data = {}, bgColorValue }) => {
  const { name, description, pictures } = data;

  let bgColorData = bgColorValue ? "#"+bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }


  return (
    <>
      <section
      style={myStyle}
        className={`mc_container_fluid p-spacerB p-spacerT ${categorysliderStyle.categorySlider_cb}`}
      >
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className={`${categorysliderStyle.col_full}`}>
                <div className="c-text-container text-center">
                    {name && (
                        <div className="heading-title h-title" dangerouslySetInnerHTML={{ __html: name }} />
                    )}
                                
                    {description && (
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    )}
                                  
                </div>
              </div>
            </div>

            <CategorySliderList categorysliderStyle={categorysliderStyle} pictures={pictures} />
            
          </div>
        </div>
      </section>
    </>
  );
};

export default CategorySlider;