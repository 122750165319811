import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./RootReducer";
import createSagaMiddleware from "redux-saga";
import { RootSaga } from "./RootSaga";
import { createWrapper } from "next-redux-wrapper";

const makeStore = (context) => {
  let state = RootReducer;
  let sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
      alert("There is some problem. Please check console!");
      console.error("ERROR", err);
    },
  });

  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;
  const Store = createStore(
    state,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  Store.sagaTask = sagaMiddleware.run(RootSaga);

  return Store;
};

const wrapper = createWrapper(makeStore);

export { wrapper };
