import React, { useId, useState, useEffect } from "react";
import faqsStyle from "../../../styles/sass/faqs.module.scss";
import FaqListing from "./FaqListing";
import { ObjAccordion } from "../../../other-object/ObjAccordion";
import Link from "next/link";
import { useRouter } from "next/router";


const Faqs = ({ data = {}, bgColorValue }) => {
  const { name, meta } = data;
  /* const [faqs, setFaqs] = useState([]); */
  const [faqsStrip, setFaqsStrip] = useState(false);
  const router = useRouter();
  
  let bgColorData = bgColorValue ? "#" + bgColorValue : "initial";
  let myStyle = {
    background:bgColorData
  }
  
  /* useEffect(() => {
    setFaqs(meta?.items);
  }, []); */

  useEffect(() => {
    setFaqsStrip(router.pathname === "/");
  }, [router.pathname]);

  return (
    <>
      <section
      style={myStyle} 
        className={`mc_container_fluid p-spacerT p-spacerB ${faqsStyle["faqs-cb"]}`}
      >
        <div className="mc_row_new">
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <div className={`${faqsStyle["col_full"]}`}>
                <div className="c-text-container text-center">
                  <div
                    className="heading-title h-title"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                </div>
              </div>
            </div>
            {meta?.items?.length > 0 && (
              <>
                {meta?.items.map((faq, idx) => {
                  return (
                    <FaqListing
                      key={idx}
                      title={faq.question}
                      description={faq.answer}
                    />
                  );
                })}
              </>
            )}
          </div>

          {faqsStrip && (
            <div className="mc_container_inner c-text-container">
              <div className="faqStrip">
                <div className="faq-col-full">
                  <h2 className="heading-title h-title-2">Having trouble deciding?</h2>
                </div>
                  <div className="faq-col-auto text-right">
                      <Link href="/faqs">
                        <a className="btn-faq">Read More</a></Link>
                      <Link href="/contact-us">
                        <a className="btn-faq">Contact Us</a>
                      </Link>
                  </div>
              </div>
            </div>
          )}



        </div>
      </section>
    </>
  );
};

export default Faqs;
