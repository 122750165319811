const Actions = {
  FETCH_ALL_CMS: "FETCH_ALL_CMS",
  FETCH_ALL_CMS_SUCCESS: "FETCH_ALL_CMS_SUCCESS",
  FETCH_ALL_CMS_FAILURE: "FETCH_ALL_CMS_FAILURE",

  CREATE_CMS: "CREATE_CMS",
  CREATE_CMS_SUCCESS: "CREATE_CMS_SUCCESS",
  CREATE_CMS_FAILURE: "CREATE_CMS_FAILURE",

  UPDATE_CMS: "UPDATE_CMS",
  UPDATE_CMS_SUCCESS: "UPDATE_CMS_SUCCESS",
  UPDATE_CMS_FAILURE: "UPDATE_CMS_FAILURE",
};

export default Actions;
